import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { originalOrder } from '../../utils/sortedKeyValue'
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
import { AuthService } from 'src/app/services/auth.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-trocar-senha',
  templateUrl: './trocar-senha.component.html',
  styleUrls: ['./trocar-senha.component.scss']
})
export class TrocarSenhaComponent implements OnInit {

  protected showValidation : boolean = false;
  protected nameUserIsNull : boolean = false;

  private chaveDeCriptografia = 'safatraceb2ml!';

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    let username = sessionStorage.getItem('user');

    if(username != undefined && username != ""){
      this.form.get('username')?.setValue(username);
      this.nameUserIsNull = false;
    }else this.nameUserIsNull = true;

    let password = sessionStorage.getItem('password');

    if(password != undefined && password != ""){
      password = CryptoJS.AES.decrypt(password, this.chaveDeCriptografia).toString(CryptoJS.enc.Utf8);
      this.form.get('oldPassword')?.setValue(password);
      this.nameUserIsNull = false;
    }else this.nameUserIsNull = true;
    
  }

  form: FormGroup = this.fb.group({
    username: ['', Validators.required],
    oldPassword: ['', Validators.required],
    newPassword: ['', [Validators.required, this.maiusculaValidator, this.minusculaValidator, this.numerosValidator, this.tamanhoValidator, this.caractereEspecialValidator]],
  })

  teste = 10

  submitForm() {
    if (this.form.valid) {
      this.authService.changePassword(this.form.value).subscribe(res=> {
        const dialogref = this.dialog.open(ModalConfirmComponent, {
          data: {
            successText: 'Senha alterada com sucesso!',
            success: true,
          },
        });

        sessionStorage.setItem('user', "");
        sessionStorage.setItem('password', "");
        
        dialogref.afterClosed().subscribe(result => {
          this.router.navigate(['/login']);
         });
      }, error => {
        const dialogref = this.dialog.open(ModalConfirmComponent, {
          data: {
            errorText: 'Não foi possível alterar a senha',
            error: true,
          },
        });
        dialogref.afterClosed().subscribe(result => {
          this.router.navigate(['/login']);
        });
      })
    }
  }

  public showValidations() {
    this.showValidation = true;
  }

  protected maiusculaValidator(control: AbstractControl) {
    const newPassword = control.value as string;

    if(!/[A-Z]/.test(newPassword)) {
        return { maiuscula: true };
    }else return null;
  }

  protected minusculaValidator(control: AbstractControl) {
    const newPassword = control.value as string;

    if(!/[a-z]/.test(newPassword)) {
        return { minuscula: true };
    }else return null;
  }

  protected numerosValidator(control: AbstractControl) {
    const newPassword = control.value as string;

    if(!/\d/.test(newPassword)) {
        return { numeros: true };
    }else return null;
  }

  protected tamanhoValidator(control: AbstractControl) {
    const newPassword = control.value as string;

    if(newPassword.length < 8) {
        return { tamanho: true };
    }else return null;
  }

  protected caractereEspecialValidator(control: AbstractControl) {
    const newPassword = control.value as string;
    const caractereEspecialRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

    if(!caractereEspecialRegex.test(newPassword)) {
        return { caractere: true };
    }else return null;
  }

  back() {
    this.router.navigate(['/login']);
  }

  originalOrder = originalOrder
}
