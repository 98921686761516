import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-overlay-map-link',
  templateUrl: './modal-overlay-map-link.component.html',
  styleUrls: ['./modal-overlay-map-link.component.scss']
})
export class ModalOverlayMapLinkComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ModalOverlayMapLinkComponent>,
  ) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.dialogRef.close();
  }

}
