import { FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from './app.service';
import { Router } from '@angular/router';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";
import * as CryptoJS from 'crypto-js';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private chaveDeCriptografia = 'safatraceb2ml!';
  showLogin = true;

  readonly urlApi = 'http://qa.agrotransparencia.com.br:9001';

  set(key: string, value: any): boolean {
    if (this.storage) {
      this.storage.setItem(key, JSON.stringify(value));
      return true;
    }
    return false;
  }

  get(key: string): any {
    if (this.storage) {
      let obj = this.storage.getItem(key);
      return JSON.parse(obj!);
    }
    return null;
  }
  private storage: Storage;

  constructor(
    private router: Router,
    private appService: AppService,
    private http: HttpClient
  ) {
    this.storage = window.sessionStorage;
  }
  showLoginEmmiter = new EventEmitter<boolean>();
  loginByTokenEmmiter = new EventEmitter<boolean>();

  auth() {
    const auth = sessionStorage.getItem('token');
    if (auth) {
      const decoded: any = jwt_decode(auth);
      if (decoded && decoded.exp) {
        const expirationDate = new Date(decoded.exp * 1000)
        const dateNow = new Date();
        if (expirationDate < dateNow) {
          this.showLogin = true;
        } else {
          this.showLogin = false;
        }
      }
    }
    //this.showLogin = auth !== undefined ? false : true;
    this.showLoginEmmiter.emit(this.showLogin);
    return !this.showLogin;
  }

  // login(username: string, password: string) {
  //   sessionStorage.removeItem('token');
  //   sessionStorage.removeItem('user');
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('user');
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  //   const body = {
  //     username: username,
  //     password: password
  //   };

  //   this.http.post(`${environment.api}/login`, body, { headers: headers, responseType: 'text' }).subscribe(
  //     (response: any) => {
  //       const token = response;
  //       // Medida paliativa para quando resolver o endpoint de autenticação
  //       if (!response.includes("DOCTYPE html")) {
  //         this.set('token', token);
  //         var decoded: any = jwt_decode(token);
  //         if (decoded && decoded.exp) {
  //           const expirationDate = new Date(decoded.exp * 1000)
  //           const dateNow = new Date();
  //           if (expirationDate < dateNow) {
  //             return "Token Expirado"
  //           } else {
  //             this.set('user', decoded);
  //             this.set('loginByToken', false);
  //             this.loginByTokenEmmiter.emit(false);
  //             if (decoded.authorities.some((item: string) => item === "ROLE_GEOMONITORING_CLIENT_LEGACY" || item === "ROLE_GEOMONITORING_CLIENT" || item === "ROLE_GEOMONITORING_ADMIN")) {
  //               this.router.navigate(['/safe-gis/']);
  //             } else {
  //               this.router.navigate(['/home']);
  //             }
  //             return "Login efetuado com sucesso";
  //           }
  //         }
  //       }
  //       alert("Usuário ou senha incorretos");
  //       return response;
  //     },
  //     (error: any) => {
  //       const newError = JSON.parse(error.error)
  //       if (error && newError && newError.status === 'CHANGE_PASSWORD') {
  //         sessionStorage.setItem('user', body.username);
  //         sessionStorage.setItem('password', CryptoJS.AES.encrypt(body.password, this.chaveDeCriptografia).toString());
  //         this.router.navigate(['/trocar-senha']);
  //       }
  //       return error;
  //     }
  //   );
  // }

  login(username: string, password: string): Observable<string> {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const body = {
      username: username,
      password: password
    };

    return this.http.post(`${environment.api}/login`, body, { headers: headers, responseType: 'text' }).pipe(
      map((response: any) => {
        const token = response;
        // Medida paliativa para quando resolver o endpoint de autenticação
        if (!response.includes("DOCTYPE html")) {
          this.set('token', token);
          var decoded: any = jwt_decode(token);
          if (decoded && decoded.exp) {
            const expirationDate = new Date(decoded.exp * 1000)
            const dateNow = new Date();
            if (expirationDate < dateNow) {
              throw new Error("Token Expirado");
            } else {
              this.set('user', decoded);
              this.set('loginByToken', false);
              this.loginByTokenEmmiter.emit(false);
              if (decoded.authorities.some((item: string) => item === "ROLE_DEFAULT_FILE_SENDER")) {
                this.router.navigate(['/safe-gis/solicitacoes/interactions']);
              }else if (decoded.authorities.some((item: string) => item === "ROLE_GEOMONITORING_CLIENT_LEGACY" || item === "ROLE_GEOMONITORING_CLIENT" || item === "ROLE_GEOMONITORING_ADMIN")) {
              
                
                this.router.navigate(['/safe-gis/']);
              } else {
                this.router.navigate(['/home']);
              }
              return "Login efetuado com sucesso";
            }
          }
        }
        throw new Error("Usuário ou senha incorretos");
      }),
      catchError((error: any) => {
        const newError = JSON.parse(error.error);
        if (error && newError && newError.status === 'CHANGE_PASSWORD') {
          sessionStorage.setItem('user', body.username);
          sessionStorage.setItem('password', CryptoJS.AES.encrypt(body.password, this.chaveDeCriptografia).toString());
          this.router.navigate(['/trocar-senha']);
          return throwError("Necessário alterar a senha");
        }
        return throwError("Erro durante o login");
      })
    );
  }

  logout() {
    this.showLoginEmmiter.emit(true);
    this.storage.removeItem('token');
    this.storage.removeItem('user');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    this.router.navigateByUrl('login');
    const currentUrl = this.router.url;
    if (currentUrl.includes('/safe-gis')) {
      this.router.navigateByUrl('/login');
    } else {
      this.router.navigateByUrl('/login');
    }
    this.appService.isSidebarPinned = false;
    this.set('loginByToken', false);
  }

  loginByToken(token: any) {
    if (!token.includes("DOCTYPE html")) {
      this.set('token', token);
      var decoded: any = jwt_decode(token);
      if (decoded && decoded.exp) {
        const expirationDate = new Date(decoded.exp * 1000)
        const dateNow = new Date();
        if (expirationDate < dateNow) {
          return "Token Expirado"
        } else {
          this.set('user', decoded);
          this.set('loginByToken', true);
          this.router.navigate(['/home']);
          return "Login efetuado com sucesso";
        }
      }
    }
    return "Usuário ou senha incorreta";
  }


  changePassword(body: { username: string, oldPassword: string, newPassword: string }) {

    return this.http.post(`${environment.api}/user/change-password`, body, {})
      .pipe(res => res, error => error)
  }


  createNewUser(username: string, email: string, token: string, permissions: string) {
    const body = {
      username,
      email,
      token,
      permissions,
    };

    return this.http.post(`${environment.api}/user/register`, body, {}).pipe(res => res, error => error)
  }

  forgotPassword(email: string) {
    return this.http.post(`${environment.api}/user/forgot-password?email=${email}`, {}, {});
  }

}
