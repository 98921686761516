import { HttpClientModule } from '@angular/common/http';
import { RecuperarSenhaModule } from './pages/recuperar-senha/recuperar-senha.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginModule } from './pages/login/login.module';
import { ComponentsModule } from './components/components.module';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { SessionLoginComponent } from './pages/session-login/session-login.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { GoogleChartsModule } from 'angular-google-charts';
import { SolicitacoesModule } from './pages/safe-gis/solicitacoes/solicitacoes.module';
import { TrocarSenhaComponent } from './pages/trocar-senha/trocar-senha.component';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from './shared/shared.module';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { MessagesComponent } from './pages/safe-gis/solicitacoes/messages/messages.component';
registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    SessionLoginComponent,
    TrocarSenhaComponent,
  ],
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    BrowserModule,
    AppRoutingModule,
    ComponentsModule,
    FontAwesomeModule,
    LoginModule,
    RecuperarSenhaModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatTableModule,
    GoogleChartsModule,
    MatIconModule,
    SharedModule,
  ],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide:  DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(library: FaIconLibrary) {
    library.addIcons(faCoffee);
    library.addIconPacks(fas, far);
  }
}
