import { KeyValue } from '@angular/common';
export const originalOrder = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => {
    return 0;
}

export const reverseKeyOrder = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => {
    return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
}

export const valueOrder = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => {
    return a.value.localeCompare(b.value);
}
