import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { originalOrder } from '../../utils/sortedKeyValue'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.scss']
})
export class RecuperarSenhaComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) { }
  ngOnInit(): void {

  }

  form: FormGroup = this.fb.group({
    //user: [''],
    email: ['', Validators.required],
  })

  teste = 10

  submitForm() {
    if (this.form.valid) {
      this.authService.forgotPassword(this.form.get('email')?.value).subscribe(res=> {
        const dialogref = this.dialog.open(ModalConfirmComponent, {
          data: {
            successText: 'Senha enviada para o email!',
            success: true,
          },
        });
        dialogref.afterClosed().subscribe(result => {
          this.router.navigate(['/login']);
         });
      }, error => {
        const dialogref = this.dialog.open(ModalConfirmComponent, {
          data: {
            successText: 'Senha enviada para o email!',
            success: true,
          },
        });
        dialogref.afterClosed().subscribe(result => {
          this.router.navigate(['/login']);
        });
      })
    }
  }


  back() {
    this.router.navigate(['/login']);
  }

  originalOrder = originalOrder

}
