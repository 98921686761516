import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentPipe } from 'src/app/pipes/document.pipe';
import { FeaturePipe } from 'src/app/pipes/feature.pipe';
import { MaskDocumentPipe } from '../pipes/mask-document.pipe';

@NgModule({
  declarations: [
    DocumentPipe,
    FeaturePipe,
    MaskDocumentPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DocumentPipe,
    FeaturePipe,
    MaskDocumentPipe,
  ]
})
export class SharedModule { }
