import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-session-login',
  templateUrl: './session-login.component.html',
  styleUrls: ['./session-login.component.scss']
})
export class SessionLoginComponent implements OnInit {
  token!: string | null;
  private storage: Storage;
  constructor( private route: ActivatedRoute, private router: Router ) {
    this.storage = window.sessionStorage;
   }


  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get('token');
    if (this.token) {
      this.router.navigateByUrl('/home')
    } else {
      this.router.navigateByUrl('/login')
    }
  }

  set(key: string, value: any): boolean {
    if (this.storage) {
      this.storage.setItem(key, JSON.stringify(value));
      return true;
    }
    return false;
  }

}
