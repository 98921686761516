import { FormBuilder, FormGroup } from '@angular/forms';
export const dropdownMenu = (form: FormGroup) => {
  var x: any,
    i: any,
    j: any,
    l: any,
    ll: any,
    selElmnt: any,
    a,
    b: any,
    c,
    formGet: any;
  // After ngOnInit start
  setTimeout(() => {
    /*look for any elements with the class "custom-select":*/
    x = document.getElementsByClassName('form-group');
    l = x.length;
    for (i = 0; i < l; i++) {
      let z = x[i].getElementsByTagName('select')[0];
      if (z == null) continue;
      formGet = z.getAttribute('ng-reflect-name');
      selElmnt = x[i].getElementsByTagName('select')[0];
      ll = selElmnt.length;
      /*for each element, create a new DIV that will act as the selected item:*/
      a = document.createElement('DIV');
      a.setAttribute('class', 'select-selected form-input-select');
      a.setAttribute('id', formGet);
      let beforeLabel = x[i].getElementsByTagName('label')[0].parentNode;
      beforeLabel.insertBefore(a, x[i].getElementsByTagName('label')[0]);
      let beforeDiv = x[i].getElementsByClassName(
        'select-selected form-input-select'
      )[0];
      /*for each element, create a new DIV that will contain the option list:*/
      b = document.createElement('DIV');
      b.setAttribute('class', 'select-items select-hide');
      for (j = 0; j < ll; j++) {
        /*for each option in the original select element,
    create a new DIV that will act as an option item:*/
        c = document.createElement('DIV');
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener('click', function (e) {
          /*when an item is clicked, update the original select box,
        and the selected item:*/
          let y: any, i: any, k: any, s: any, h: any, sl: any, yl: any;
          s = this.parentNode?.parentElement?.getElementsByTagName('select')[0];
          sl = s.length;
          h = this.parentNode?.previousSibling;
          formGet = s.getAttribute('ng-reflect-name');
          for (i = 0; i < sl; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.value = s.options[i].value;
              form.get(formGet)?.setValue(s.value);
              y =
                this.parentElement?.getElementsByClassName('same-as-selected');
              yl = y.length;
              for (k = 0; k < yl; k++) {
                y[k].removeAttribute('class');
              }
              h.innerHTML = '';
              if (s.value !== '') {
                form.get(formGet)?.markAsDirty();
                h.innerHTML = this.innerHTML;
                this.setAttribute('class', 'same-as-selected');
              }
              break;
            }
          }
          h.click();
        });
        b.appendChild(c);
      }
      beforeDiv.after(b);
      a.addEventListener('click', function (e) {
        formGet = this.getAttribute('id')
        form.get(formGet)?.markAsTouched();
        /*when the select box is clicked, close any other select boxes,
      and open/close the current select box:*/
        e.stopPropagation();
        closeAllSelect(this);
        this.parentElement?.classList.toggle('focus')
        this.nextElementSibling?.classList?.toggle('select-hide');
        this.classList.toggle('select-arrow-active');
      });

    
    }
    function closeAllSelect(elmnt: any) {
      /*a function that will close all select boxes in the document,
  except the current select box:*/
      var x,
        y,
        i,
        xl,
        yl,
        arrNo = [];
      x = document.getElementsByClassName('select-items');
      y = document.getElementsByClassName('select-selected');
      xl = x.length;
      yl = y.length;
      for (i = 0; i < yl; i++) {
        if (elmnt == y[i]) {
          arrNo.push(i);
        } else {
          y[i].parentElement?.classList.remove('focus')
          y[i].classList.remove('select-arrow-active');
        }
      }
      for (i = 0; i < xl; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add('select-hide');
        }
      }
    }
    /*if the user clicks anywhere outside the select box,
then close all select boxes:*/
    document.addEventListener('click', closeAllSelect);
  }, 0);
};
