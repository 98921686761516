import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskDocument'
})
export class MaskDocumentPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    const documentNumber: string = value.replace(/\D/g, '');
    if (documentNumber.length === 11) {
      return `${documentNumber.substr(0, 3)}.***.***-${documentNumber.substr(9)}`;
    } else if (documentNumber.length === 14) {
      return `${documentNumber.substr(0, 2)}.***.***/****-${documentNumber.substr(12)}`;
    }
    return documentNumber;
  }

}
