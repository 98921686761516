import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecuperarSenhaComponent } from './recuperar-senha.component';



@NgModule({
  declarations: [
    RecuperarSenhaComponent
  ],
  imports: [
    RouterModule,
    ReactiveFormsModule,
    CommonModule
  ],
  exports:[RecuperarSenhaComponent]
})
export class RecuperarSenhaModule { }
