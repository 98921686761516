import { AuthGuard } from './guard/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecuperarSenhaComponent } from './pages/recuperar-senha/recuperar-senha.component';
import { SessionLoginComponent } from './pages/session-login/session-login.component';
import { TrocarSenhaComponent } from './pages/trocar-senha/trocar-senha.component';
import { MessagesComponent } from './pages/safe-gis/solicitacoes/messages/messages.component';


export const routes: Routes = [
  { path: 'safe-gis', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/safe-gis/safe-gis.module').then(e => e.SafeGisModule) },
  { path: 'login/:id', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'session-login', component: SessionLoginComponent },
  { path: 'recuperar-senha', component: RecuperarSenhaComponent },
  { path: 'trocar-senha', component: TrocarSenhaComponent },
  { path: '**', redirectTo: 'login' }

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
