import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import { dropdownMenu } from 'src/app/utils/select-dropdown';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    // fundo escuro que fica atrás do modal
    trigger('overlay', [
      transition(':enter', [
        // Inicia com o opacity zerado
        style({ opacity: 0 }),

        // efetua a animação de 250ms para o
        // o opacity de 0 até 0.5
        animate('250ms', style({ opacity: 0.5 })),
      ]),
      transition(':leave', [
        // Quando for esconder o overlay,
        // anima do opacity atual, 0.5, até
        // o valor 0
        animate('500ms', style({ opacity: 0 })),
      ]),
    ]),

    // animação na parte branca do modal
    trigger('modal', [
      transition(':enter', [
        // inicia com o modal "lá em cima"
        style({ top: -999 }),

        // e finaliza com o modal no meio da tela
        animate('500ms', style({ top: '50%' })),
      ]),
      transition(':leave', [
        // para esconder o modal, basta
        // "jogar ele lá para cima da tela"
        animate('250ms', style({ top: -999 })),
      ]),
    ]),
  ],
})
export class ModalComponent implements OnInit {
  @Input() closeModal!: Function;
  @Input() show: boolean = false;
  @Input() typeModal!: string;
  @Input() typeContent!: string;
  @Input() modalResult!: boolean;
  // @Input() primaryFunction!: (arg: string, ...aff: any[]) => void;
  @Input() actionPrimary!: Function;
  @Input() actionSecodary!: Function;
  @ContentChild('content') content!: ElementRef;
  @Input() form!: FormGroup;
  @Output() finished = new EventEmitter<any>();
  constructor(private router: Router) { }

  ngOnInit(): void { }
  toggle() {
    this.show = !this.show;
    if (this.closeModal) this.closeModal();
    if (this.form) dropdownMenu(this.form);
  }

  saveDatas() {
    this.finished.emit(null);
  }

  submit() {
    if(this.form.valid) {
      this.actionPrimary(this.form.get('typeAudit')?.value);
    }
  }
  /*   callFunction(){
    this.actionPrimary()
  } */
}

